function NewQuery(searchTerm, currentPage, labelsPerPage) {
  return {
    "query": {
      "bool": {
        "should": [
          {
            "match": {
              "label.strip_whitespace": {
                "query": searchTerm,
                "boost": 100,
                "_name": "exact match * score_multiply_100"
              }
            }
          },
          {
            "bool": {
              "must": [
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.first_two_chars",
                      "label.first_char_double"
                    ]
                  }
                },
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": ["label"]
                  }
                }
              ],
              "boost": 60,
              "_name": "(OO or ON) and ONE * score_multiply_60"
            }
          },
          {
            "bool": {
              "must": [
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": ["label"]
                  }
                }
              ],
              "boost": 40,
              "_name": "ONE * score_multiply_40"
            }
          },
          {
            "bool": {
              "must": [
                {
                  "match": {
                    "label.first_char_double": searchTerm
                  }
                },
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.last_3_chars_trible_last",
                      "label.last_3_chars_double_last",
                      "label.last_3_chars_double_penultimate"
                    ]
                  }
                }
              ],
              "boost": 15,
              "_name": "ON and (EEE or NNE or NNE) * score_multiply_15"
            }
          },
          {
            "bool": {
              "must": [
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.first_char_double",
                      "label.first_two_chars"
                    ]
                  }
                },
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.last_2_chars",
                      "label.last_2_chars_drop_last_keep_first_two"
                    ]
                  }
                }
              ],
              "boost": 2,
              "_name": "(OO or ON) and (*NE or NE*) * score_multiply_10"
            }
          },
          {
            "bool": {
              "must": [
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": ["label.transform*"]
                  }
                }
              ],
              "boost": 1,
              "_name": "transform * score_multiply_1"
            }
          },
          {
            "bool": {
              "must": [
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": ["label.transform*"]
                  }
                },
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.first_char_double",
                      "label.first_two_chars"
                    ]
                  }
                }
              ],
              "boost": 2,
              "_name": "transform AND (OO or ON) * score_multiply_2"
            }
          },
          {
            "bool": {
              "must": [
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": ["label.transform*"]
                  }
                },
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.first_char_double",
                      "label.first_two_chars"
                    ]
                  }
                },
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.last_3_chars_trible_last",
                      "label.last_3_chars_double_last",
                      "label.last_3_chars_double_penultimate"
                    ]
                  }
                }
              ],
              "boost": 3,
              "_name": "transform AND (OO or ON) AND (EEE or NEE or NNE) * score_multiply_3"
            }
          },
          {
            "match": {
              "label.ngram": {
                "query": searchTerm,
                "boost": 10,
                "_name": "wildcard * score_multiply_10"
              }
            }
          },
          {
            "term": {
              "supplier_id": {
                "value": 19,
                "boost": 10,
                "_name": "supplier_id 19 * score_multiply_10"
              }
            }
          }
        ],
        "filter": [
          {
            "term": {
              "available": "true"
            }
          }
        ],
        "minimum_should_match": 1
      }
    },
    "_source": ["label"],
    "size": labelsPerPage,
    "from": (currentPage - 1) * labelsPerPage,
    "sort": [ "_score" ],
    "track_total_hits": true
  };
}
export default NewQuery;
